<template>
  <div>
    <!--begin::User-->
    <div class="card card-custom">
      <div class="card-header flex-wrap border-0 pt-6 pb-0">
        <div class="card-title">
          <h3 class="card-label">
            {{ $t('categories.categories') }}
            <span class="text-muted pt-2 font-size-sm d-block"></span>
          </h3>
        </div>
        <div class="card-toolbar">
        </div>
      </div>

      <div class="card-body">
        <div class="row">
          <b-card-body class="col-md-4">
            <div class="col-lg-12 mb-5 p-0">
              <div class="input-group">
                <input type="text" class="form-control" v-model="search" :placeholder="$t('search')"/>
              </div>
            </div>
            <v-treeview
                :items="tree_list"
                :search="search"
                :filter="filter"
                return-object
                selected-color="warning"
                :selection-type="selectionType"
                hoverable
                expand-icon="mdi-chevron-down"
                on-icon="mdi-bookmark"
                off-icon="mdi-bookmark-outline"
                indeterminate-icon="mdi-bookmark-minus"
                :active.sync="item"
                activatable
            >
              <template v-slot:prepend="{ item, open }">
                <v-icon v-if="!item.file">
                  {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
                </v-icon>
                <v-icon v-else>
                  {{ files[item.file] }}
                </v-icon>
              </template>
            </v-treeview>
          </b-card-body>
          <b-card-body class="col-md-8">
            <div class="form-group row">
              <div class="col-lg-12 mb-5 d-flex justify-content-end">

                <button type="button" v-if="$can('categories.create')" class="btn btn-primary mr-2 ml-2" @click="addNewCategory">{{ $t('categories.add_new_category') }}</button>
                <button type="button" v-if="$can('categories.create')" class="btn btn-warning mr-2 ml-2" @click="addNewSubCategory">{{ $t('categories.add_new_sub_category') }}</button>

              </div>
              <div class="col-lg-12 mb-5">
                <label>{{ $t('categories.name') }}<span class="text-danger">*</span></label>
                <div class="input-group">
                  <input type="text" class="form-control" :class="validation && validation.name ? 'is-invalid' : ''" v-model="data.name" :placeholder="$t('categories.name')"/>
                  <span v-if="validation && validation.name" class="fv-plugins-message-container invalid-feedback">{{ validation.name[0] }}</span>
                </div>
              </div>
              <div class="col-lg-6 mb-5">
                <label for="units">{{ $t('categories.unit') }}</label>
                <multiselect id="units" v-model="unit"
                             :placeholder="$t('categories.unit')"
                             label="name"
                             track-by="id"
                             :options="units"
                             :multiple="false"
                             :taggable="false"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false">
                </multiselect>
                <span v-if="validation && validation.units" class="fv-plugins-message-container invalid-feedback">{{ validation.units[0] }}</span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('categories.return_days') }}</label>
                <input type="number" v-model="data.allowable_return_days" class="form-control"
                       :class="validation && validation.allowable_return_days ? 'is-invalid' : ''"
                       :placeholder="$t('categories.return_days')"/>
                <span v-if="validation && validation.allowable_return_days" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.allowable_return_days[0] }}
                            </span>
              </div>
              <div class="col-lg-12 mb-5">
                <label for="printers">{{ $t('categories.printers') }}</label>
                <multiselect id="printers" v-model="data.printers"
                             :placeholder="$t('categories.printers')"
                             label="name"
                             track-by="id"
                             :options="printers_list"
                             :multiple="true"
                             :taggable="false"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false">
                </multiselect>
                <span v-if="validation && validation.printer" class="fv-plugins-message-container invalid-feedback">{{ validation.printer[0] }}</span>
              </div>



              <div class="col-lg-12 mb-5">
                <label>{{ $t('categories.description') }}</label>
                <div class="input-group">
                  <textarea class="form-control" :class="validation && validation.description ? 'is-invalid' : ''" v-model="data.description" :placeholder="$t('categories.description')"/>
                  <span v-if="validation && validation.description" class="fv-plugins-message-container invalid-feedback">{{ validation.description[0] }}</span>
                </div>
              </div>

              <div class="col-lg-6 mb-5">
                <label>{{ $t('categories.upload_image') }}</label>
                <div class="form-group">
                  <upload-file
                      @file="listenerAttachment"
                      :inner-id="'image'"
                      :placeholder="$t('categories.upload_image')"
                      :upload="dir_upload"
                      :start-link="'base'"
                      v-bind:valueprop="{name:'image',path:image_url}" v-if="reloadUploadAttachment">
                  </upload-file>
                </div>
              </div>

              <div class="col-lg-6 mb-5">
                <b-form-checkbox size="lg" v-model="data.is_active" name="check-button" switch>{{ $t('status') }}</b-form-checkbox>
              </div>
              <div class="col-lg-6 mb-5" v-if="company.is_activity_laundry">
                <b-form-checkbox size="lg" v-model="data.type" name="check-button" switch>{{ $t('categories.is_laundries_services') }}</b-form-checkbox>
              </div>

              <div v-if="data.type" class="col-lg-12 pt-8">
                <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
                  <h6 class="mt-2">{{ $t('categories.services') }}</h6>
                  <button type="button" class="btn btn-primary" @click="addToRepeaterLaundryServices">{{ $t('add_more') }}</button>
                </div>
                <div class="form-group">
                  <table class="table" @keyup.alt.enter="addToRepeaterLaundryServices" @keyup.alt.46="deleteFromRepeaterLaundryServices(0)">
                    <thead>
                    <tr>
                      <th width="30%">{{ $t('categories.service') }}</th>
                      <th width="20%">{{ $t('categories.quantity') }}</th>
                      <th width="20%">{{ $t('categories.price') }}</th>
                      <th width="40%">{{ $t('categories.tax') }}</th>
                      <th width="40%">{{ $t('status') }}</th>
                      <th width="20%" class="text-center">{{ $t('actions') }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(ls, index) in laundry_services" :key="index">
                      <td>
                        <select name="" id="laundry_service_id" v-model="ls.laundry_service_id" @input="selectTax(index)" class="custom-select">
                          <option v-for="row in services_list" :value="row.id" :key="'laundry_service_'+row.id">{{ row.name }}</option>
                        </select>
                      </td>
                      <td>
                        <input type="number" v-model="ls.qty" @input="changeQty(index)" class="form-control" :placeholder="$t('categories.quantity')"/>

                      </td>
                      <td>
                        <input type="number" v-model="ls.price" class="form-control" :placeholder="$t('categories.price')"/>

                      </td>
                      <td>
                        <multiselect v-model="ls.tax"
                                     :placeholder="$t('categories.tax')"
                                     label="name"
                                     disabled
                                     track-by="id"
                                     :options="taxes"
                                     :multiple="false"
                                     @input="handlingPercentageValue(index)"
                                     :taggable="false"
                                     :show-labels="false"
                                     :show-no-options="false"
                                     :show-no-results="false">
                        </multiselect>
                      </td>

                      <td>
                        <b-form-checkbox size="lg" v-model="ls.is_active" name="check-button" switch></b-form-checkbox>

                      </td>

                      <td class="text-center">
                        <v-icon class="text-danger" @click="deleteFromRepeaterLaundryServices(index)">mdi-delete</v-icon>

                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div v-else class="col-lg-12 pt-8">
                <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
                  <h6 class="mt-2">{{ $t('items.taxes') }}</h6>
                  <button type="button" class="btn btn-primary" @click="addToRepeater">{{ $t('add_more') }}</button>
                </div>
                <div class="form-group">
                  <table class="table" @keyup.alt.enter="addToRepeater" @keyup.alt.46="deleteFromRepeater(0)">
                    <thead>
                    <tr>
                      <th width="40%">{{ $t('items.tax') }}</th>
                      <th width="30%">{{ $t('items.percentage') }}</th>
                      <th width="20%">{{ $t('minimum') }}</th>
                      <th width="40%">{{ $t('items.apply_priority_tax') }}</th>
                      <th width="20%" class="text-center">{{ $t('actions') }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(tx, index) in data_taxes" :key="index">
                      <td>
                        <multiselect v-model="tx.tax"
                                     :placeholder="$t('items.tax')"
                                     label="name"
                                     track-by="id"
                                     :options="taxes"
                                     :multiple="false"
                                     @input="handlingPercentageValue(index)"
                                     :taggable="false"
                                     :show-labels="false"
                                     :show-no-options="false"
                                     :show-no-results="false">
                        </multiselect>
                      </td>
                      <td>
                        <input type="text" v-model="tx.percentage" class="form-control"
                               :placeholder="$t('items.percentage')"/>

                      </td>
                      <td>
                        <input type="number" v-model="tx.minimum" class="form-control" :placeholder="$t('minimum')"/>

                      </td>
                      <td>
                        <select name="" id="apply_priority_tax" v-model="tx.priority" @input="onPriorityTaxChanged(index)" class="custom-select">
                          <option v-for="row in apply_priority_list" :value="row.id" :key="row.id">{{ row.name }}</option>
                        </select>
                      </td>
                      <td class="text-center">
                        <v-icon class="text-danger" @click="deleteFromRepeater(index)">mdi-delete</v-icon>

                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>



              <div class="col-lg-12 mb-5">
                <div class="row justify-content-end">
                  <button type="button" v-if="$can('categories.create') || $can('categories.update')" class="btn btn-primary mr-2 ml-2" @click="save">{{ $t('save') }}</button>
                  <button type="button" class="btn btn-light mr-2 ml-2" @click="afterSave">{{ $t('cancel') }}</button>
                  <button type="button" v-if="$can('categories.delete')" :disabled="!idEdit" class="btn btn-danger mr-2 ml-2" @click="deleteItem">{{ $t('delete') }}</button>
                </div>
              </div>


            </div>
          </b-card-body>
        </div>


      </div>
      <!--            <div class="card-footer">-->
      <!--                <div class="row">-->
      <!--                    <div class="col-lg-6">-->
      <!--                        <button type="button" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>-->
      <!--                    </div>-->
      <!--                </div>-->
      <!--            </div>-->

    </div>
    <!--end::User-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import {mapGetters} from "vuex";

export default {
  name: "form-categories",
  data() {
    return {
      mainRoute: 'settings/categories',
      mainRouteDependency: 'base/dependency',
      item: [],
      selectionType: 'independent',

      tree_list: [],
      printers: [],
      printers_list: [],
      search: '',
      data: {
        name: null,
        description: null,
        parent_id: null,
        image: null,
        printers: null,
        allowable_return_days: null,
        is_active: true,
        unit_id: true,
        type: false,
      },
      idEdit: null,
      image_url: null,
      dir_upload: 'categories',
      reloadUploadAttachment: true,
      isEditing: false,
      validation: null,
      unit:null,
      repeater_taxes: {id: null, tax: null, percentage: null, priority: null, minimum: 0},
      laundry_services: [],
      laundry_services_row: {id: null, tax: null, laundry_service_id: null, qty: 1, price: 0, is_active: false},
      data_taxes: [],
      taxes: [],
      units: [],
      services_list: [],
      apply_priority_list: [
        {id: 1, name: 1},
        {id: 2, name: 2},
        {id: 3, name: 3},
        {id: 4, name: 4},
        {id: 5, name: 5},
      ],
    };
  },
  watch: {
    item: function (val) {
      this.reloadUploadAttachment = false;
      if (val[0]) {
        val = val[0];
        this.idEdit = val.id;
        this.data.name = val.name;
        this.data.allowable_return_days = val.allowable_return_days;
        this.data.description = val.description;
        this.data.parent_id = val.parent_id;
        this.data.unit_id = val.unit;
        this.unit = val.unit;
        this.data.image = val.image;
        this.data.printers = val.printers;
        this.printers = val.printers_obj;
        this.data.is_active = val.is_active == 1 ? true : false;
        this.data.type = val.type == 1 ? true : false;
        this.image_url = val.image_url;
        this.data_taxes = val.data_taxes;
        this.laundry_services = val.laundry_services;
      } else {
        this.idEdit = null;
        this.data.name = null
        this.data.allowable_return_days = null
        this.data.description = null;
        this.data.parent_id = null;
        this.data.unit = null;
        this.data.unit_id = null;
        this.data.image = null;
        this.data.printers = null;
        this.printers = null;
        this.data.is_active = true;
        this.data.type = false;
        this.image_url = null;
        this.data_taxes = [];
        this.laundry_services = [];
        this.addToRepeater();
      }
      setTimeout(() => {
        this.reloadUploadAttachment = true;
      }, 100);
    },
    unit: function (val) {
      if (val) {
        this.data.unit_id = val.id;
      } else {
        this.data.unit_id = null;
      }
    },
  },
  computed: {
    filter() {
      return (item, search, textKey) => item[textKey].indexOf(search) > -1
    },
    ...mapGetters({company: "getCompanyData"}),
  },
  methods: {
    save() {
      // this.data.is_active = this.data.is_active ? 1 : 0;
      if (this.idEdit) {
        this.update();
      } else {
        this.create();
      }
    },
    create() {

      this.laundry_services = this.data.type ? this.laundry_services : [];
      this.data_taxes = (!this.data.type) ? this.data_taxes : [];
      let _data = {...this.data};
      _data.type = _data.type? 1 : 0;
      ApiService.post(this.mainRoute, {
        ..._data,
        data_taxes: this.data_taxes,
        laundry_services: this.laundry_services,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.getList();
        this.afterSave();
      }).catch((error) => {
        this.validation = error.response ? error.response.data.errors : null;
      });
    },
    update() {
      this.laundry_services = this.data.type ? this.laundry_services : [];
      this.data_taxes = (!this.data.type) ? this.data_taxes : [];
      let _data = {...this.data};
      _data.type = _data.type? 1 : 0;
      ApiService.put(this.mainRoute + '/' + this.idEdit, {
        ..._data,
        data_taxes: this.data_taxes,
        laundry_services: this.laundry_services,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.getList();
        this.afterSave();
      }).catch((error) => {
        this.validation = error.response ? error.response.data.errors : null;
      });
    },
    // getData() {
    //     ApiService.get(this.mainRoute+'/get').then((response) => {
    //         this.isEditing = true;
    //         this.data = response.data.data;
    //     });
    // },
    getList() {
      ApiService.get(this.mainRoute).then((response) => {
        this.tree_list = response.data.data;
      });
    },
    getLaundryServices() {
      ApiService.get(`${this.mainRouteDependency}/laundry_services`).then((response) => {
        this.services_list = response.data.data;
      });
    },

    afterSave() {
      this.item = [];
      this.unit = null;
      // this.reloadUploadAttachment = false;
      // this.idEdit = null;
      // this.data.name = null
      // this.data.description = null;
      // this.data.parent_id = null;
      // this.data.image = null;
      // this.data.is_active = false;
      // this.image_url = null;
      // this.reloadUploadAttachment = true;

      this.data_taxes= [];
      this.laundry_services= [];
      this.addToRepeater();

    },
    listenerAttachment(event) {
      if (event) {
        this.image_url = event.pathDB;
        this.data.image = event.name;
      }
    },
    actionDelete(item) {
      ApiService.delete(this.mainRoute + "/" + item.id).then((response) => {
        this.getList();
        this.$successAlert(response.data.message);
        this.afterSave();
      }).catch((error) => {
        this.$errorAlert(error)
      })
    },
    deleteItem() {
      if (this.item[0]) {
        let _item = this.item[0];
        this.$confirmAlert('', this.actionDelete, _item);
      }
    },
    addNewCategory() {
      this.reloadUploadAttachment = false;
      this.idEdit = null;
      this.data.name = null
      this.data.allowable_return_days = null
      this.data.description = null;
      this.data.parent_id = null;
      this.data.image = null;
      this.data.printers = null;
      this.printers = null;
      this.data.is_active = true;
      this.data.type = false;
      this.image_url = null;
      this.data_taxes= [];
      this.laundry_services= [];

      setTimeout(() => {
        this.reloadUploadAttachment = true;
        this.addToRepeater();
      }, 100);
    },
    addNewSubCategory() {
      this.reloadUploadAttachment = false;
      this.data.parent_id = this.idEdit;
      this.idEdit = null;
      this.data.name = null
      this.data.allowable_return_days = null
      this.data.description = null;
      this.data.image = null;
      this.data.printers = null;
      this.printers = null;
      this.data.is_active = true;
      this.data.type = false;
      this.image_url = null;
      this.data_taxes= [];
      this.laundry_services= [];

      setTimeout(() => {
        this.reloadUploadAttachment = true;
        this.addToRepeater();
      }, 100);
    },
    getPrinters() {
      ApiService.get(this.mainRouteDependency + "/printers").then((response) => {
        this.printers_list = response.data.data.map((row) => {
          return {id: row.id, name: row.name};
        });
      });
    },
    selectPrinters() {
      this.data.printers = this.printers.map((row) => row.id);
    },
    getTaxes() {
      ApiService.get(this.mainRouteDependency + "/taxes").then((response) => {
        this.taxes = response.data.data;
      });
    },
    getUnits() {
      ApiService.get(this.mainRouteDependency + "/units").then((response) => {
        this.units = response.data.data;
      });
    },
    addToRepeater() {
      this.repeater_taxes.priority = this.data_taxes.length + 1;
      this.data_taxes.unshift(this.repeater_taxes);
      this.repeater_taxes = {id: null, tax: null, percentage: null, priority: null, minimum: 0};
    },
    deleteFromRepeater(index) {
      this.data_taxes.splice(index, 1);
      this.data_taxes = this.data_taxes.map((row, index) =>{
        row.priority = this.data_taxes.length - index;
        return row;
      });

    },
    addToRepeaterLaundryServices() {
      this.laundry_services.unshift(this.laundry_services_row);
      this.laundry_services_row = {id: null, tax: null, laundry_service_id: null, qty: 1, price: 0, is_active: false};
    },
    deleteFromRepeaterLaundryServices(index) {
      this.laundry_services.splice(index, 1);
    },
    selectTax: _.debounce(function (index){
      let _services = this.services_list.find((row) => row.id == this.laundry_services[index].laundry_service_id);
      if(_services){
        this.laundry_services[index].tax = _services.tax;
      }else{
        this.laundry_services[index].tax = null;
      }
    }, 100),
    changeQty: _.debounce(function (index){
      if(this.laundry_services[index].qty && parseInt(this.laundry_services[index].qty) < 1){
        this.laundry_services[index].qty = 1;
      }
    }, 100),
    onPriorityTaxChanged: _.debounce(function (index){
      if ((this.data_taxes.length - 1) == index){
        this.data_taxes[index].priority = 1;
      }else {
        this.data_taxes[index].priority = this.data_taxes[index + 1].priority + 1
      }

    }, 100),
    handlingPercentageValue(index) {
      if (this.data_taxes[index] && this.data_taxes[index].tax){
        let _value_rate = this.data_taxes[index].tax.value_rate;
        this.data_taxes[index].percentage = _value_rate;
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.Categories")}]);
    this.getList();
    this.getUnits();
    this.getTaxes();
    this.getLaundryServices();
    // this.getPrinters();

    this.addToRepeater();
  },
};
</script>

<style>
.v-treeview-node__root {
  min-height: 37px !important;
  cursor: pointer;
}

.v-treeview-node--active {
  background-color: #C9F7F5;
}
</style>
